.root {
  grid-column: span 2;
  height: 100%;
  position: relative;
}

.root:hover {
  img {
    transform: scale(1.05);
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: opacity 1s var(--theme-movement-easing) 0ms;
  height: 100%;
}

.styledImage {
  border-top-left-radius: var(--theme-borderRadius);
  border-top-right-radius: var(--theme-borderRadius);
  border: 1px solid var(--theme-color-lightGray2);
  border-bottom: 0;
  overflow: hidden;

  img {
    aspect-ratio: 16 / 9;
    transition: transform var(--theme-movement-duration)
      var(--theme-movement-easing);
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: var(--theme-spacing-16);
  width: 100%;
  flex-grow: 1;
  border: 1px solid var(--theme-color-lightGray2);
  border-top: 0;
  border-bottom-left-radius: var(--theme-borderRadius);
  border-bottom-right-radius: var(--theme-borderRadius);
  position: relative;

  > * + * {
    margin-top: var(--theme-spacing-16);
  }

  @media (--theme-mediaQuery-desktop) {
    padding: var(--theme-spacing-24);
  }
}
